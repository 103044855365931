import { Link } from 'react-router-dom';

function Logo({ marginLeft, marginRight }) {
  return (
    <Link to="/">
      <img
        src="/../../resources/ROI-logo.png"
        alt="company Logo"
        style={{
          marginLeft, marginRight, maxWidth: '195px', minWidth: '150px', width: '25vw',
        }}
      />
    </Link>
  );
}

export default Logo;
