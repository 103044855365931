import './App.css';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Home from './components/Home';
import Consultation from './components/Consultation';
import Logo from './components/Logo';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <ToastProvider placement="top-center">
        <div className="App">
          <Logo marginLeft="5vw" />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/get-in-touch" exact component={Consultation} />
            <Redirect to="/" />
          </Switch>
          <Footer />
        </div>
      </ToastProvider>
    </Router>
  );
}

export default App;
