import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Highlight = styled.strong`
  color: black;
`;

const Card = styled.div`
  text-align: center;
  margin: 0 5vw;
  color: #858e96;
  font-size: 0.8rem;

  @media (max-width: 830px) {
    margin-top: 30px;
    margin-left: 100px;
    margin-right: 100px;
  }

  @media (max-width: 600px) {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
  }

  @media (hover: hover) {
    margin: -15px 0 -10px 0;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: fit-content;
    height: 250px;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    background-color: white;
    transform: rotateY(180deg);
  }
`;

const Gap = styled.div`
  height: 20px;
`;

const Pointer = styled.div`
  border: 1px solid white;
  border-radius: 20px;
  padding: 9px 25px;
  color: white;
  margin: 10px;
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;

  @media (max-width: 700px) {
    width: 80%;
  }
`;

const Home = () => (
  <>
    <h1>
      We are a specialized digital consulting firm helping
      <br />
      businesses grow, scale and achieve positive
      {' '}
      <Highlight>ROi</Highlight>
      .
    </h1>
    <div className="slider">
      <div className="main">
        <div className="display-outer">
          <div className="display">
            <img className="pic shadow1" src="/../resources/pic1.jpg" alt="Revenue Optimization" />
            <Card>
              <img src="/../resources/revenue-icon.png" alt="Revenue Optimization" />
              <h2>Revenue Optimization</h2>
              <p>
                Want to increase revenue?
                Let us help you to harness the power of insights to drive
                sales, reduce acquisition costs and increase your customer’s LTV.
              </p>
            </Card>
          </div>
        </div>
        <div className="display-outer">
          <div className="display">
            <img className="pic shadow2" src="/../resources/pic2.jpg" alt="Growth" />
            <Card>
              <img src="/../resources/growth-icon.png" alt="Growth" />
              <h2>Growth</h2>
              <p>
                Are you an early stage startup or a company now looking for accelerated
                growth? Let’s inject some growth hacks into your strategies to help you
                achieve unprecedented growth.
              </p>
            </Card>
          </div>
        </div>
        <div className="display-outer">
          <div className="display">
            <img className="pic shadow1" src="/../resources/pic3.jpg" alt="Startup Mentoring" />
            <Card>
              <img src="/../resources/startup-icon.png" alt="Startup Mentoring" />
              <h2>Startup Mentoring</h2>
              <p>
                Let us identify critical factors holding back business growth and leadership
                performance. Also, we can help you make a pitch
                to prospective investors and get the much-needed funding.
              </p>
            </Card>
          </div>
        </div>
        <div className="display-outer">
          <div className="display">
            <img className="pic shadow2" src="/../resources/pic4.jpg" alt="Digital Marketing" />
            <Card>
              <img src="/../resources/digitalm-icon.png" alt="Digital Marketing" />
              <h2>Digital Marketing</h2>
              <p>
                We deliver quality traffic and app installs with a keen eye on the performance
                KPIs. We can increase organic traffic to your website and help build your brand
                through viral content and influencer marketing campaigns.
              </p>
            </Card>
          </div>
        </div>
        <div className="display-outer">
          <div className="display">
            <img className="pic shadow1" src="/../resources/pic5.jpg" alt="Automation" />
            <Card>
              <img src="/../resources/automation-icon.png" alt="Automation" />
              <h2>Automation</h2>
              <p>
                Automate your marketing function to track the life cycle of every customer from
                source level to every click/tap on your portal/app. We can help you to check the
                responsiveness of every customer towards engagement and retention campaigns.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>
    <div className="box">
      <h2>One solution doesn’t fit to every organization</h2>
      We look for perfect technology solutions by analyzing your scenario.
      <Gap />
      <Pointer>Digital Intelligence</Pointer>
      <Pointer>Performance Marketing</Pointer>
      <Pointer>Startup Mentoring</Pointer>
      <Pointer>Brand Strategy</Pointer>
      <Pointer>Organic Marketing</Pointer>
      <Pointer>Growth Hacking</Pointer>
      <Pointer>Digital Transformation</Pointer>
    </div>
    <div className="booking">
      <h2>Would you like to learn more about ROIIM business consulting?</h2>
      <Link to="/get-in-touch">
        <button type="button" className="button">
          <i className="fa fa-location-arrow" aria-hidden="true" />
          {' '}
          Book a free consultation now!
        </button>
      </Link>
    </div>
  </>
);

export default Home;
