import { useToasts } from 'react-toast-notifications';
import './index.css';

const Consultation = () => {
  const { addToast } = useToasts();
  function sendEmail(e) {
    e.preventDefault();

    // eslint-disable-next-line no-undef
    emailjs.sendForm('service_toxqxqa', 'template_n1r9wcj', e.target, 'user_ACFHcMKV5DQjZ5nubBg6c').then(
      () => {
        addToast('Your message has successfully sent!', {
          appearance: 'success',
          autoDismiss: true,
        });
      },
      () => {
        addToast('Your message was not sent!', {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    );
  }
  return (
    <div className="consultation">
      <form className="form" onSubmit={sendEmail}>
        <label>
          Your Name (required)
          <input
            type="text"
            name="user_name"
            placeholder="Enter your name"
            required
          />
        </label>
        <label>
          Your Email (required)
          <input
            type="email"
            name="user_email"
            placeholder="Enter your email"
            required
          />
        </label>
        <label>
          Your Message (required)
          <textarea
            name="message"
            placeholder="what would you like to chat about?"
            required
          />
        </label>
        <button
          type="submit"
        >
          Send
        </button>
      </form>

      <h2>
        Do get in touch to say hello, set up a meeting, or chat.
      </h2>
      {/* <img src="/../resources/contact-page.png" alt="ideas" /> */}
    </div>
  );
};

export default Consultation;
