import styled from 'styled-components';
import './index.css';
import Logo from '../Logo';

const Highlight = styled.strong`
  color: black;
`;

const Icon = styled.img`
  margin-left: 1vw;

  @media (max-width: 750px) {
    margin-left: 0;
  }
`;

function Footer() {
  return (
    <>
      <div className="footer">
        <Logo marginRight="20px" />
        <h3>Let’s set you moving towards positive ROi.</h3>
        <div>
          <a href="https://www.linkedin.com/in/satyamahapatra/" rel="noreferrer" target="_blank">
            <Icon src="/../resources/linkedin-icon.png" alt="linkedin" />
          </a>
          <a href="https://twitter.com/SatyaMahapatra" rel="noreferrer" target="_blank">
            <Icon src="/../resources/twitter-icon.png" alt="twitter" />
          </a>
        </div>
      </div>
      <div className="copyright">
        Copyright © 2019
        {' '}
        <Highlight>ROIIM</Highlight>
        . All Rights Reserved.
      </div>
    </>
  );
}

export default Footer;
